@import './layout/variables.scss';
@import './layout/title.scss';
@import './layout/button.scss';
@import './layout/form.scss';
@import './layout/grid.scss';

body{
    font-family: $font-1;
    font-weight: $font-weight-light;
    color: $color-3;
    background-color: $color-8;

    @include responsiveAbove(xxxl){
        font-size: 18px;
    }
    
}

img{
    display: block;
    width: 100%;
    height: auto;

    &.object-fit-cover{
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
}

a{
    color: $color-3;
}

p{
    line-height: 25px;
    margin-bottom: spaceCalculator(2);

    &:last-child{
        margin-bottom: 0;
    }
}

strong{
    font-weight: $font-weight-medium;
}

.section{
    transition: filter .2s linear;
}

body.menu-opened{
    .section{
        filter: blur(1.5rem);

        &.no-blur{
            filter:none !important;
        }
    }
}