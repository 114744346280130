//FONT-SIZE
$font-size-body-small: 14px;
$font-size-body-default: 16px;
$font-size-body-large: 18px;


//BUTTON
$button-height-small: 40px;
$button-height-mediul: 48px;
$button-height-small: 56px;


//LAYOUT
$header-height-mobile: spaceCalculator(9);
$header-height-desktop: spaceCalculator(10);
$header-height-desktop-large: spaceCalculator(12);
$header-height-desktop-xlarge: spaceCalculator(12);

//TRANSITION
$transition-linear: linear;
$transition-cubic: cubic-bezier(0.42, 0, 0.58, 1);

//PARAGRAPHE MARGE
$p-margin: 16px;

//FORM
$form-input-label-color: $color-3;
$form-input-border-color: $color-3;
$form-input-focus-border-color: 2px solid $color-4;
$form-input-error-color: $color-2;
$form-input-error-border-color: 2px solid $form-input-error-color;
$form-input-border-radius: spaceCalculator(1);
$form-label-color: $color-3;