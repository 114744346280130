h1{
    &.h1__multicolor{
        color: $color-2;
        font-weight: $font-weight-regular;
        font-size: spaceCalculator(6);

        .bold{
            font-weight: $font-weight-extra-bold;
            font-size: spaceCalculator(9);
            text-transform: uppercase;
        }

        .bold-blue{
            color: $color-4;
            font-weight: $font-weight-black;
            font-size: spaceCalculator(7);
            text-transform: uppercase;
            margin-top: spaceCalculator(2);
            display: inline-block;
        }
    }
}

h2{
    text-align: center;
    font-size: spaceCalculator(5);
    font-weight: $font-weight-bold;
    margin-bottom: spaceCalculator(3);;

    @include responsiveAbove(md){
        font-size: spaceCalculator(6);
    }

    &.write{
        font-family: $font-2;
        font-size: spaceCalculator(13);
        font-weight: normal;
    }
}

h3{

}

h4{
    
}