.evenements-gourmand{
    padding-top: spaceCalculator(2);

    &__intro{
        text-align: center;
    }

    &__expertise{
        &__title{
            position: relative;
            border-radius: spaceCalculator(2);
            overflow: hidden;
            h1{
                width: 100%;
                text-align: center;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                z-index: 1;
                color: $color-5;
                font-weight: $font-weight-bold;
                font-size: spaceCalculator(3);

                @include responsiveAbove(md){
                    font-weight: $font-weight-black;
                    font-size: spaceCalculator(5);
                }
            }
        }

        &__item{
            padding-bottom: spaceCalculator(3);
            border-bottom: 1px solid $color-6;
            margin-bottom: spaceCalculator(3);

            &:last-child{
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
            h2{
                color: $color-4;
                text-align: left;
                span{
                    display: block;
                    color: $color-2;
                }
            }

            .right{
                ul{
                    padding-left: spaceCalculator(2);

                    li{
                        line-height: spaceCalculator(3);
                        margin-bottom: spaceCalculator(1);
                    }
                }
            }
        }
    }
}