$color-1: #FFFAF6;
$color-2: #D17653;
$color-3: #293965;
$color-4: #86A0D0;
$color-5: #FFFFFF;
$color-6: #F2DECD;
$color-7: #F4E8DF;
$color-8: #FFF8EE;
$color-9: #D3A581;

$hover: #D95421;
$active-menu: $color-2;
