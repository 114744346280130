.home{
    &__hero{
        padding-top: spaceCalculator(3);
        h1{
            flex-grow: 1;

            @include responsiveAbove(md){
                font-size: spaceCalculator(4);

                .bold{
                    font-size: spaceCalculator(7);
                }

                .bold-blue{
                    font-size: spaceCalculator(5);
                }
            }

            @include responsiveAbove(lg){
                font-size: spaceCalculator(6);

                .bold{
                    font-size: spaceCalculator(9);
                }

                .bold-blue{
                    font-size: spaceCalculator(6);
                }
            }

        }

        &__slider{
            margin-top: spaceCalculator(3);
            display: flex;
            column-gap: spaceCalculator(2);

            @include responsiveAbove(md){
                width: 66%;
                margin-top: spaceCalculator(0);
            }

            .slide{
                width: 15%;
                position: relative;
                overflow: hidden;
                border-radius: spaceCalculator(3);
                height: spaceCalculator(56);
                cursor: pointer;

                img{
                    width: auto;
                    height: 100%;
                }

                .title{
                    position: absolute;
                    color: $color-8;
                    font-size: spaceCalculator(5);
                    transform: rotate(-90deg);
                    transform-origin: 16px 2px;
                    left: 0;
                    bottom: 0;
                }

                .btn{
                    position: absolute;
                    right: spaceCalculator(3);
                    bottom: spaceCalculator(3);
                    opacity: 0;
                    transition: opacity 0.2s $transition-cubic;
                }

                &.open{
                    .btn{
                        opacity: 1;
                    }

                    .title{
                        font-weight: $font-weight-extra-bold;
                    }
                }
            }
        }
    }

    &__ecole{
        &__item{
            &.picture{
                border-radius: spaceCalculator(3) spaceCalculator(3) 0 0;
                overflow: hidden;

                @include responsiveAbove(md){
                    border-radius: spaceCalculator(6) 0 0 spaceCalculator(6);

                    img{
                        height: 100%;
                    }
                }
            }
            &.bg-blue{
                align-items: center;
                justify-content: center;
                background-color: $color-4;
                padding: spaceCalculator(3);
                border-radius: 0 0 spaceCalculator(3) spaceCalculator(3);
                text-align: center;
                color: $color-8;

                @include responsiveAbove(md){
                    border-radius: 0 spaceCalculator(6) spaceCalculator(6) 0;
                }

                .btn{
                    margin-top: spaceCalculator(3);
                }
            }
        }
    }

    &__lechef{
        text-align: center;

        img{
            border-radius: spaceCalculator(3) spaceCalculator(3) 0 0;

            @include responsiveAbove(md){
                border-radius: spaceCalculator(6) spaceCalculator(6) 0 0;
            }
        }

        h2{
            margin-top: spaceCalculator(5);
        }

        .separation-title{
            width: 30%;
            margin: spaceCalculator(5) auto spaceCalculator(5) auto;
            height: 8px;
            background: $color-2;

            @include responsiveAbove(md){
                width: 15%;
            }
        }
    }
}





  