.footer{
    background-color: $color-7;
    

    .section-row{
        padding: spaceCalculator(3);
        row-gap: spaceCalculator(2);

        @include responsiveAbove(md){
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: spaceCalculator(3);
        }
    }
    

    .logo-yanndefrance{
        background: url(../images/footer/bg-footer.png) no-repeat left center / cover;
        padding: spaceCalculator(3) 0;

        @include responsiveAbove(md){
            flex-basis: 25%;
        }

        a{
            display: inline-block;
            width: spaceCalculator(14);
        }
    }

    .menu{
        @include responsiveAbove(md){
            display: flex;
            flex-direction: column;
            row-gap: spaceCalculator(1);
        }

        a{ 
            color: $color-3;
            margin-right: spaceCalculator(2);

            @include responsiveAbove(md){
                margin-right: spaceCalculator(0);
            }
        }
    }

    .infos{
        h1{
            font-size: spaceCalculator(2);

            .bold{
                font-size: spaceCalculator(4);
            }
    
            .bold-blue{
                font-size: spaceCalculator(3);
                margin-top: spaceCalculator(1);
            }
        }

        .adresse{
            font-size: 14px;
            margin-top: spaceCalculator(2);
        }
    }

    .social{
        display: flex;
        a{
            
            margin-right: spaceCalculator(2);

            img{
                height: spaceCalculator(3);
            }
        }
    }

    .mentions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: spaceCalculator(1);
        font-size: 12px;
        border-top: 1px solid $color-9;

        @include responsiveAbove(md){
            padding: spaceCalculator(1);
        }

        &__menu{
            a{
                margin-right: spaceCalculator(2);
            }
        }

        &__signature{
            img{
                width: auto;
                height: 9px;
                display: inline;
            }

            @include responsiveAbove(md){
                display: block;
            }
        }
    }
}