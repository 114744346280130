.mentions{
    
    h1{
        font-size: spaceCalculator(3);
        font-weight: $font-weight-bold;
        margin-bottom: spaceCalculator(2);
    }

    h2{
        font-size: spaceCalculator(3);
        font-weight: $font-weight-medium;
        margin-bottom: spaceCalculator(2);
        text-align: left;
        color: $color-4;
    }

    h3{
        font-size: spaceCalculator(2);
        font-weight: $font-weight-medium;
        margin-bottom: spaceCalculator(1);
    }

    strong{
        font-weight: $font-weight-medium;
    }
}