.bottom-contact{
    justify-content: center;
    align-items: center;
    row-gap: spaceCalculator(2);
    border-top: 3px solid $color-2;
    padding: spaceCalculator(5) 0;
    background: url('../images/bottom-contact/bg-bottom-contact.jpg') no-repeat left center / cover;

    @include responsiveAbove(lg){
        padding: spaceCalculator(8) 0;
    }

    @include responsiveAbove(xl){
        padding: spaceCalculator(10) 0;
    }

    p{
        font-size: spaceCalculator(3);
        font-weight: $font-weight-bold;

        @include responsiveAbove(lg){
            font-size: spaceCalculator(4);
        }
    }
}