.wrapper-card, .card-special{
    @include responsiveAbove(sm){
        width: calc(50% - #{spaceCalculator(3)});
    }

    @include responsiveAbove(lg){
        width: calc(33% - #{spaceCalculator(3)});
    }

    @include responsiveAbove(xxxl){
        width: calc(25% - #{spaceCalculator(3)});
    }
}

.wrapper-card{
    padding-right: spaceCalculator(4);
}
.card{
    position: relative;
    background-color: $color-5;
    padding: spaceCalculator(2) spaceCalculator(2) spaceCalculator(6) spaceCalculator(2);
    border-radius: spaceCalculator(3);
    box-shadow: none;
    transition: box-shadow .2s $transition-cubic;
    height: 100%;

    &:hover{
        box-shadow: 0px 0px 49px 0px rgba(209, 118, 83, 0.33);
    }

    h3{
        font-size: spaceCalculator(3);
        color: $color-4;
        font-weight: $font-weight-extra-bold;
        margin-bottom: spaceCalculator(2);
        text-transform: uppercase;
        height: spaceCalculator(8);

        span{
            color: $color-2;
            font-size: spaceCalculator(2);
            display: block;
            text-transform: capitalize;
        }
    }

    &__infos{
        position: relative;
        flex-direction: row;
        min-height: 250px;

        .details{
            width: 100%;
            padding-right: calc(50% - 32px);

            p{
                font-size: 12px;
                line-height: 18px;
                color: $color-9;
            }

            /*.time-price{
                font-size: spaceCalculator(3);
                font-weight: $font-weight-semi-bold;

                span{
                    font-size: 13px;
                }
            }*/
        }

        .picture{
            width: 50%;
            position: absolute;
            height: 100%;
            right: - spaceCalculator(6);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: spaceCalculator(3);
        }
    }

    &__recettes{
        margin-top: spaceCalculator(2);
        font-size: 12px;

        span{
            display: block;
            margin-bottom: spaceCalculator(1);
            font-weight: $font-weight-extra-bold;
            font-size: spaceCalculator(2);
        }
    }

    &__contact{
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: -28px;
    }

    
    &-special{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .card{
            justify-content: center;
            display: flex;
            flex-direction: column;
            row-gap: spaceCalculator(3);
        }

        h3{
            text-align: center;
            span{
                color: $color-3;
            }
            color: $color-8;
        }
        .picture-pro{
            border-radius: 8px;
            overflow: hidden;
        }
        .group-tarif{
            padding: 0;
            display: flex;
            flex-direction: column;
            row-gap: spaceCalculator(2);

            &__line{
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: spaceCalculator(8);
                .name{
                    font-weight: $font-weight-medium;

                    .time{
                        display: block;
                        font-weight: $font-weight-light;
                        font-size: 14px;
                    }

                    .non-compris{
                        display: block;
                        font-size: 12px;
                        font-weight: $font-weight-light;
                    }
                }
                .tarif{
                    padding: 4px;
                    border-radius: 3px;
                    background-color: $color-5;
                    font-size: spaceCalculator(3);
                    font-weight: $font-weight-semi-bold;
                    color: $color-2;
                }

                
            }
        }

        ul{
            padding-left: spaceCalculator(2);
            li{
                margin-bottom: spaceCalculator(1);
                font-size: 14px;
            }
        }

        &.bon-cadeau{
            h3{
                font-size: spaceCalculator(5);
            }

            p{
                margin-bottom: 0;
            }

            li{
                margin-bottom: spaceCalculator(1);
            }

            p, li{
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-blue{
        background-color: $color-4;
    }

    &-orange{
        background-color: $color-2;
        color: $color-8;

        h3{
            text-align: left;
        }
    }

    

}