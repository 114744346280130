.atelier{
    &__intro{
        h1{
            color: $color-4;
            font-size: spaceCalculator(6);
            font-weight: $font-weight-black;
            margin-bottom: spaceCalculator(3);

            @include responsiveAbove(md){
                font-size: spaceCalculator(7);
                text-align: center;
            }

            span{
                color: $color-2;
            }
        }

        p{
            font-weight: $font-weight-bold;

            @include responsiveAbove(md){
                text-align: center;
            }
        }
    }

    &__cible{
        row-gap: spaceCalculator(3);

        @include responsiveAbove(md){
            row-gap: 0;
            column-gap: spaceCalculator(2);
        }

        &__item{
            background-color: $color-5;
            padding: spaceCalculator(2);
            border-radius: spaceCalculator(2);
            
            h2{
                font-size: spaceCalculator(3);
                font-weight: $font-weight-bold;
                text-align: left;
                color: $color-4;
            }

            &.left h2{
                color: $color-2;
            }
    
            ul{
                padding-left: spaceCalculator(2);
                li{
                    margin-bottom: spaceCalculator(2);

                    &:last-child(){
                        margin-bottom: 0;
                    }
                }
            }
        }

    }

    &__detail-offre{
        border-radius: spaceCalculator(2);
        background-color: $color-2;
        color: $color-8;
        padding: spaceCalculator(2);
        flex-wrap: wrap;
        row-gap: spaceCalculator(3);

        @include responsiveAbove(sm){
            flex-direction: row;
            column-gap: spaceCalculator(3);
        }

        h2{
            font-size: spaceCalculator(4);
            font-weight: $font-weight-bold;
            color: $color-5;
            flex-grow: 1;
            flex-basis: 100%;
            margin-bottom: 0;

            @include responsiveAbove(md){
                font-size: spaceCalculator(4);
            }
            
            @include responsiveAbove(xxl){
                font-size: spaceCalculator(5);
            }

            span{
                font-weight: $font-weight-light;
                font-size: spaceCalculator(2);
            }
        }

        &__item{
            font-size: spaceCalculator(3);
            text-align: center;
            font-weight: $font-weight-bold;

            @include responsiveAbove(sm){
                width: calc(25%  - #{spaceCalculator(3)});
            }

            @include responsiveAbove(lg){
                width: calc(25%  - #{spaceCalculator(3)});
            }

            span{
                font-weight: $font-weight-light;
                font-size: 14px;

                @include responsiveAbove(sm){
                    font-size: 12px;
                    line-height: 1;
                }
            }
            img{
                width: auto;
                height: spaceCalculator(7);
                margin: 0 auto spaceCalculator(1) auto;

                @include responsiveAbove(md){
                    height: spaceCalculator(8);
                }
            }
        }
    }

    &__list{
        row-gap: spaceCalculator(8);
        align-items: stretch;

        @include responsiveAbove(sm){
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: spaceCalculator(3);
        }

        @include responsiveAbove(md){
            row-gap: spaceCalculator(10);
        }
    }
}