@mixin section-spacing($size) {
  @if $size == "large" {
      margin-bottom: 88px;
      margin-top: 88px;
  } @else if $size == "medium" {
      margin-bottom: 40px;
      margin-top: 40px;
  } @else if $size == "small" {
      margin-bottom: 24px;
      margin-top: 24px;
  }
}

.section-spacing-bottom-large {
  @include section-spacing("large");
  margin-top: 0;
}

.section-spacing-bottom-medium {
  @include section-spacing("medium");
  margin-top: 0;
}

.section-spacing-bottom-small {
  @include section-spacing("small");
  margin-top: 0;
}

.section-spacing-top-large {
  margin-bottom: 0;
  @include section-spacing("large");
}

.section-spacing-top-medium {
  margin-bottom: 0;
  @include section-spacing("medium");
}

.section-spacing-top-small {
  margin-bottom: 0;
  @include section-spacing("small");
}

.section-spacing-large {
  @include section-spacing("large");
}

.section-spacing-medium {
  @include section-spacing("medium");
}

.section-spacing-small {
  @include section-spacing("small");
}


//SECTION ALIGNEMENT
.section-row, .section-col{
  display: flex;
  flex-direction: column;

  @include responsiveAbove(md){
    flex-direction: row;
  }
}

.section-col{
  flex-direction: column;
}


//SECTION ROW ITEM SIZE
.section-row {
  .w-40{
    @include responsiveAbove(md){
      flex-basis: 40%;
    }
  }

  .w-50{
    @include responsiveAbove(md){
      flex-basis: 50%;
    }
  }

  .w-60{
    @include responsiveAbove(md){
      flex-basis: 60%;
    }
  }
}


//CONTAINER
.container-100{
  width: 100%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;

  @include responsiveAbove(md){
      padding-left: 2rem;
      padding-right: 2rem;
  }
}

.container{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @include responsiveAbove(md){
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include responsiveAbove(xl){
    width: 960px;
    padding: 0;
  }

  @include responsiveAbove(xxl){
      width: 1140px;
  }

  @include responsiveAbove(xxxl){
      width: 1320px;
  }
}

// MAIN
#swup{
  padding-bottom: $header-height-mobile;

  @include responsiveAbove(md){
    padding-top: $header-height-desktop + 16;
    padding-bottom: 0;
  }
}
