.yanndefrance{
    &__hero{
        border-radius: spaceCalculator(4);
        overflow: hidden;
        max-width: 450px;
        margin: spaceCalculator(3) auto 0 auto;
    }

    &__quote{
        line-height: 60px;
    }

    &__parcours{
        row-gap: spaceCalculator(2);

        @include responsiveAbove(md){
            flex-direction: column;
        }

        @include responsiveAbove(lg){
            flex-direction: row;
            row-gap: 0;
            column-gap: spaceCalculator(8);
        }
        p{
            text-align: justify;
        }
    }

    &__style-cuisine{
        @include responsiveAbove(md){
            flex-direction: column;
        }

        @include responsiveAbove(lg){
            flex-direction: row;
        }

        .left{
            background-color: $color-2;
            padding: spaceCalculator(4) spaceCalculator(2);
            border-radius: spaceCalculator(3) spaceCalculator(3) 0 0;
            @include responsiveAbove(lg){
                border-radius: spaceCalculator(4) 0 0 spaceCalculator(4);
                justify-content: center;
            }

            @include responsiveAbove(xl){
                padding: spaceCalculator(6);
            }

            @include responsiveAbove(xxl){
                padding: spaceCalculator(9);
            }
            
            h1{
                font-size: spaceCalculator(6);
                font-weight: $font-weight-black;
                margin-bottom: spaceCalculator(3);
                span{
                    color: $color-8;
                }
            }
    
            p {
                color: $color-8;
    
                span{
                    color: $color-3;
                font-weight: $font-weight-bold;
                }

                &:last-child{
                    font-weight: $font-weight-bold;
                }
            }
        }

        .right{
            min-height: 500px;
            border-radius: 0 0 spaceCalculator(3) spaceCalculator(3);
            overflow: hidden;
            background: url('../images/yanndefrance/style-cuisine.jpg') no-repeat bottom center / cover;
        
            @include responsiveAbove(lg){
                min-height: inherit;
                border-radius: 0 spaceCalculator(3) spaceCalculator(3) 0;
            }
        
        }
    }

    &__partenaires{
        h3{
            font-size: spaceCalculator(3);
            font-weight: $font-weight-bold;
            margin-bottom: spaceCalculator(2);
    
            @include responsiveAbove(lg){
                font-size: spaceCalculator(4);
            }
        }
    }
}