.termsfeed-com---palette-light.termsfeed-com---nb{
    background-color: $color-5 !important;
    color: $color-3 !important;
    border-radius: spaceCalculator(3) spaceCalculator(3) 0 0 !important;
}

.termsfeed-com---palette-light .cc-nb-title, 
.termsfeed-com---palette-light .cc-nb-text, 
.termsfeed-com---palette-light .cc-cp-body-content-entry-title, 
.termsfeed-com---palette-light .cc-cp-body-content-entry-text {
    color: $color-3 !important;
}

.termsfeed-com---palette-light .cc-nb-okagree, .termsfeed-com---palette-light .cc-nb-reject, .termsfeed-com---palette-light .cc-cp-foot-save{
    background-color: $color-3 !important;
}

.termsfeed-com---pc-dialog .cc-cp-foot-save{
    opacity: 1 !important;
}

.termsfeed-com---palette-light .cc-nb-changep{
    background-color: $color-4 !important;
    color: $color-5 !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs-item{
    background-color: $color-5 !important;
}

.termsfeed-com---palette-light .cc-cp-body-tabs-item-link{
    color: $color-3 !important;
}

.termsfeed-com---palette-light .cc-cp-body-tabs-item{
    border-color: $color-6 !important;
}

.termsfeed-com---palette-light .cc-cp-body-tabs{
    background-color: $color-5 !important;
}

.termsfeed-com---palette-light .cc-cp-foot{
    background-color: $color-7 !important;
    border-color: $color-6 !important;
}

.termsfeed-com---palette-light .cc-cp-body-content{
    background-color: $color-8 !important;
}

.termsfeed-com---palette-light .cc-pc-head-lang select:focus{
    box-shadow: 0 0 0 2px $color-3 !important;
}