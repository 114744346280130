form{
    label{
        display: block;
        margin-bottom: spaceCalculator(1);
        color: $form-input-label-color;
    }

    input, select, textarea{
        width: 100%;
        height: spaceCalculator(5);
        border-radius: $form-input-border-radius;
        border: 1px solid $form-input-border-color;
        padding: spaceCalculator(1) spaceCalculator(2);

        &:focus-visible, :focus {
            outline: none;
            border: $form-input-focus-border-color;
        }

        &.error{
            border: $form-input-error-border-color;
        }
    }

    textarea{
        height: inherit;
    }

    .error-field{
        color: $form-input-error-color;
        font-size: 12px;
    }
    
}