// RESPONSIVE
$responsive: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1350px,
    xxxl: 1700px
);

@mixin responsiveAbove($size){
    @media(min-width: map-get($responsive, $size)){
        @content;
    }
}

@mixin responsiveBelow($size){
    @media(max-width: map-get($responsive, $size)){
        @content;
    }
}

$space: 8px;
@function spaceCalculator($value1) {
    @return $value1 * $space;
}