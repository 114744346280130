
 .btn{
    &__small{
    }

    &__medium{
    }

    &__large{
    }
    
    &__contact{
      display: inline-flex;
      align-items: center;
      height: spaceCalculator(7);
      padding: spaceCalculator(1);
      border-radius: 8px;
      background-color: $color-3;
      color: $color-8;
      text-align: left;
      font-size: 14px;
      transition: background-color .2s $transition-cubic;
      
      svg{
         width: auto;
         height: 100%;
         margin-right: spaceCalculator(2);
         fill: $color-4;
         transition: fill .2s $transition-cubic;
      }

      &:hover{
        background-color: $color-4;

        svg{
          fill: $color-3;
        }
      }
    }

    &__primary{
      width: spaceCalculator(13);
      height: spaceCalculator(13);
      display: inline-flex;
      align-items: center;
      flex-shrink: 0;
      border-radius: 15px;
      background-color: $color-2;
      color: $color-8;
      font-size: 14px;
      text-align: center;
      font-weight: $font-weight-semi-bold;
      padding: spaceCalculator(1);
      transition: background-color .2s $transition-cubic;

      &:hover{
        background-color: $hover;
      }
    }
 }