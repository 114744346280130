@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'jackyband';
    src: url('../fonts/jackyband.woff2') format('woff2'),
         url('../fonts/jackyband.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$font-1: 'Poppins', sans-serif;
$font-2: 'jackyband', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
