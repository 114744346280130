.contact, .contact-success{
    &__title{
        position: relative;
        row-gap: spaceCalculator(3);
        .picture{
            width: 100%;
            border-radius: spaceCalculator(3);
            height: 250px;
            background: url('../images/home/slide-consulting.jpg') no-repeat center center / cover;
        
            @include responsiveAbove(lg){
                position: absolute;
                top: 0;
                right: 8%;
                height: 600px;
                width: 30%;
            }
        }
    }
    &__form{
        width: 100%;
        background: $color-5;
        padding: spaceCalculator(3);
        border-radius: spaceCalculator(3);

        @include responsiveAbove(md){
            padding: spaceCalculator(5);
        }

        &__item{
            width: 100%;

            @include responsiveAbove(lg){
                width: 60%;
            }
        }

        form{
            .section-row{
                column-gap: spaceCalculator(2);
                row-gap: spaceCalculator(2);
            }

            .btn-form{
                text-align: right;
                button{
                    background-color: $color-4;
                    padding: spaceCalculator(2) spaceCalculator(4);
                    border-radius: spaceCalculator(1);
                    color: $color-5;
                    text-transform: uppercase;
                }
            }
        }
    }
    
}

@include responsiveAbove(lg){
    .contact-success{
        .contact__form{
            min-height: 450px;
        }
    }
}


