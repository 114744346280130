.header{
    z-index: 1;
    height: $header-height-mobile;
    position: fixed;
    display: flex;
    width: 100%;
    padding: spaceCalculator(2);
    bottom: 0;
    background-color: $color-8;
    justify-content: space-between;
    align-items: center;

    @include responsiveAbove(md){
        top: 0;
        bottom: inherit;
        height: $header-height-desktop;
    }

    @include responsiveAbove(xxl){
        top: 0;
        bottom: inherit;
        height: $header-height-desktop-large;
    }

    &__logo{
        z-index: 1;
        width: 40%;
        @include responsiveAbove(md){
            width: auto;
        }
        img{
            width: auto;
            height: $header-height-mobile - 16;

            @include responsiveAbove(md){
                height: $header-height-desktop - 16;
            }
        
            @include responsiveAbove(xxl){
                height: $header-height-desktop-large - 16;
            }
        }
    }

    &__menu{
        position: fixed;
        background-color: $color-8;
        width: 100%;
        height: 100%;
        bottom: -100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 32px 0 32px;
        left: 0;

        @include responsiveAbove(md){
            position: initial;
            flex-direction: row;
            align-items: flex-end;
            width: auto;
            height: auto;
            bottom: inherit;
            column-gap: spaceCalculator(2);
            padding: 0 spaceCalculator(3) 0 0;
            left: inherit;
            flex-grow: 1;
            justify-content: flex-end;
        }

        @include responsiveAbove(xl){
            column-gap: spaceCalculator(4);
        }

        a{
            display: block;
            color: $color-3;
            font-size: spaceCalculator(6);
            font-family: $font-1;
            font-weight: $font-weight-extra-bold;
            margin-bottom: spaceCalculator(4);
            opacity: 0;
            transform: translateY(20);
            transition: color .3s $transition-cubic;

            @include responsiveAbove(md){
                opacity: 1;
                font-size: spaceCalculator(2);
                transform: translateY(0);
                margin-bottom: 0;
                font-weight: $font-weight-medium;
            }

            @include responsiveAbove(xxl){
                font-size: 18px
            }

            &:hover{
                color: $color-4;
            }

            &.active{
                color: $active-menu;
            }
        }
    }

    &__burger{
        width: 60px;
        height: 60px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;
        flex-grow: 1;

        span{
            width: 30px;
            height: 2px;
            display: block;
            margin-bottom: 5px;
            background-color: $color-3;
            transition: transform 0.3s $transition-cubic, opacity 0.3s $transition-cubic;

            &:last-child{
                margin-bottom: 0;
            }
        }

        @include responsiveAbove(md){
            display: none;
        }
    }

    &__contact{
        z-index: 1;
        width: 40%;
        text-align: right;
        @include responsiveAbove(md){
            width: auto;
        }

        .btn__contact{
            height: spaceCalculator(5);
            font-size: 12px;

            @include responsiveAbove(md){
                height: spaceCalculator(7);
            }
        
            @include responsiveAbove(xxl){
                height: spaceCalculator(8);
            }
        }
    }

    &.menu-open {
        .header__burger span:first-child {
            transform: rotate(45deg) translate(5px, 5px);
        }

        .header__burger span:last-child {
            transform: rotate(-45deg) translate(5px, -5px);
        }

        .header__burger span:nth-child(2) {
            opacity: 0;
            transform: rotate(0);
        }
    }
}